

import {IonContent, IonIcon, IonItem, IonLabel, IonPage} from '@ionic/vue';
import {execute} from "@/mixins/LoadingMixin";
import {computed, defineComponent, ref} from "vue";
import { createOutline, ellipseOutline } from 'ionicons/icons';
import {getJourneySet, updateJourneySet} from "@/services/JourneySetService";
import {JourneySet} from "@/models/JourneySetModel";
import {JOURNEY_SET_TYPES, JourneySetTypeId} from "@/models/JourneySetTypes";
import router from "@/router";
import {getTemplate} from "@/services/TemplateService";
import DirectionIndicator from "@/components/DirectionIndicator.vue"
import TemplateSelection from "@/components/TemplateSelection.vue";
import {useStore} from "vuex";

export default defineComponent({
    name: 'SelectJourneySetCategory',
    components: {
        IonContent,
        IonPage,
        IonItem,
        IonLabel,
        IonIcon,
        DirectionIndicator,
        TemplateSelection
    },
    props: {
        journeySetId: {
            type: String,
            required: true,
            default: ''
        },
        templateId: {
            type: String,
            required: false,
            default: ''
        }
    },
    async ionViewWillEnter() {
        this.journeySet = {} as JourneySet;
        await this.store.dispatch('app/setPageTitle', this.language.journeySetCreation);
        await execute(async () => {
            if (this.editingExisting) {
                this.journeySet = await getJourneySet(this.journeySetId);
            } else {
                const template = await getTemplate(this.templateId);
                this.journeySet.templateId = template.id;
                this.journeySet.templateName = template.name;
            }
        }, this.language.errors.gettingJourneySet);
    },
    setup(props) {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        const journeySet = ref({} as JourneySet);
        const editingExisting = computed(() => props.journeySetId.trim().toLowerCase() !== 'create');

        const selectCategory = async (categoryId: string) => {
            if (editingExisting.value) {
                await execute(async () => {
                    journeySet.value.journeySetTypeId = categoryId;
                    await updateJourneySet(journeySet.value);
                    await router.push({name: 'journeySetInfo', params: { journeySetId: props.journeySetId }});
                }, language.value.errors.savingJourneySet);
            } else {
                await router.push({name: 'journeySetInfo', params: { journeySetId: 'create', templateId: props.templateId, categoryId: categoryId }});
            }
        }
        
        const editTemplate = () => {
            if (editingExisting.value) {
                router.push({ name: 'journeySetTemplate', params: { journeySetId: props.journeySetId }});
            } else {
                router.push({ name: 'journeySetTemplate', params: { journeySetId: 'create' } });
            }
        }
        
        return {
            store,
            language,
            journeySet,
            journeySetTypes: JOURNEY_SET_TYPES,
            quadrants: JourneySetTypeId,
            createOutline,
            ellipseOutline,
            editTemplate,
            selectCategory,
            editingExisting
        }
    }
});
